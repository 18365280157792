<template>
  <div class="bg-h-primary h-screen dotted-bg">
    <b-row class="md:justify-between w-100 md:p-5 py-5 items-center ">
      <b-col class="md:flex justify-start md:pr-5 items-center w-1/6">
          <img width="60px" src="@/assets/images/logo/logo.png" alt="haio-logo">
      </b-col>
      <b-col class="flex justify-end items-center md:gap-2  text-white w-4/5 md:text-base text-xs">
      </b-col>
    </b-row>

    <b-row class="m-0 mt-16 justify-content-center align-items-center w-100 lg:p-12">

      <b-col lg="6" class="container align-items-center justify-center">
        <div class="w-3/4 m-auto bg-white p-5 rounded-lg shadow">
          <b-card-title title-tag="h2" class="font-weight-bold text-h-primary text-4xl text-center" style="margin-bottom: 36px;">
            ورود یا ثبت نام
          </b-card-title>
          <!-- form -->
          <div class="">

            <form v-if="step == 1" @submit.prevent>
              <vs-input
                ref="mobileInput"
                type="text"
                v-validate="'required|min:11'"
                data-vv-validate-on="blur"
                name="mobile"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                label-placeholder="شماره همراه"
                v-model="mobile"
                class="w-full"
                dir="ltr"
              />
              <span data-cy="mobile-error" class="text-danger text-sm">{{
                errors.first("mobile")
              }}</span>

              <div class="flex flex-wrap justify-between my-5">
                <div class="flex flex-wrap justify-end w-full mb-3">

                  <vs-button
                    data-cy="loginBtn"
                    class="rounded  bg-h-secondary w-full text-lg"
                    :disabled="!validateForm"
                    @click="loginOtp"
                    >دریافت کد تایید</vs-button
                  >
                </div>
              </div>
            </form>

            <form v-if="step == 2" @submit.prevent>
              <vs-input
                type="text"
                aria-required="true"
                data-vv-validate-on="blur"
                name="otp_code"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                label-placeholder="کد تایید (ارسال شده به شماره همراه)"
                v-model="otp_code"
                class="w-full"
                dir="ltr"
              />
              <span data-cy="otp_code-error" class="text-danger text-sm">{{
                errors.first("otp_code")
              }}</span>

              <div class="flex flex-wrap justify-between my-5">
                <div class="flex flex-wrap justify-end w-full mb-3">

                  <vs-button
                    data-cy="loginBtn"
                    class="rounded  bg-h-secondary w-full text-lg"
                    :disabled="!validateForm"
                    @click="loginJWT"
                    >ورود</vs-button
                  >

                  <div v-if="isCounting" class="mt-2">
<!--                    شمارش معکوس: {{ count }}-->
                    <vs-button disabled="disabled" v-if="count !== 0">{{ count }}</vs-button>
                    <vs-button v-if="count === 0" @click="resetCount">ارسال مجدد کد</vs-button>
                  </div>

                </div>
              </div>
            </form>


            <div class="flex flex-wrap justify-between my-3 -mt-3">

<!--              <vs-button @click="cantLogin = true" type="" class="p-0 text-h-secondary text-center d-block text-sm md:text-base">نمیتوانم وارد بشوم!</vs-button>-->
<!--              <vs-popup :active.sync="cantLogin" title="راهنمایی رفع مشکلات ورود  ">-->
<!--                <p>-->
<!--                  در ابتدا اطمینان حاصل کنید که اطلاعات را به درستی وارد میکنید</p>-->
<!--                  <p>جهت تکمیل ثبت نام و ساخت کامل حساب کاربری باید ایمیل را از طریق لینک ارسال شده به ایمیل تان تایید و شماره همراه را از طریق کد پیامک شده تایید کنید. </p>-->
<!--                  <p>تا زمانی که ایمیل و شماره تماس تایید نشوند امکان ورود به حساب کاربری میسر نیست.</p>-->
<!--                  <p>دقت داشته باشید که پوشه spam یا junk را نیز در ایمیل خود جهت تایید بررسی کنید.</p>-->
<!--                  <br />-->
<!--                  <p>در نهایت اگر نتوانستید ایمیل یا شماره همراه خود را تایید کنید به صفحه-->
<!--                    <a href="https://console.haio.ir/pages/verifyaccount" class="text-success">فعال سازی حساب کاربری</a>-->
<!--                    جهت دریافت مجدد لینک یا کد فعال سازی اقدام کنید</p>-->

<!--                  <p>درصورتی که بعد از ثبت نام یا بعد ثبت درخواست ارسال کد در صفحه فعال سازی حساب کاربری پیامک تایید را دریافت نکردید از طریق چت آنلاین در سایت اصلی هایو و یا شماره های تماس ارائه شده با پشتیبانی ارتباط بگیرید.</p>-->
<!--              </vs-popup>-->

            </div>
          </div>

        </div>
        <div class="d-block text-center text-lg m-auto mt-4 text-white">
      </div>

      </b-col>
      <!-- <b-col >
        <div class="hidden lg:block">

          <img src="../../../assets/images/pages/login/eydaneh.jpg" class="rounded-lg w-2/3">
        </div>
      </b-col> -->

    </b-row>

  </div>

</template>

<script>
import { Validator } from 'vee-validate'
import Logo from '../../../layouts/components/Logo.vue'

const dict = {
  custom: {
    mobile: {
      required: 'لطفا شماره همراه را وارد کنید',
      min: 'لطفا شماره همراه را صحیح وارد کنید'
    }
  }
}

// register custom messages
Validator.localize('en', dict)

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    Logo,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton
  },
  data () {
    return {
      status: '',
      step: 1,
      mobile: '',
      otp_code: '',
      cantLogin: false,
      checkbox_remember_me: true,
      route: this.$route,
      isCounting: false,
      count: 0,
      interval: null
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.mobile !== ''
    }

  },
  methods: {
    loginOtp () {
      this.$vs.loading()
      this.$http.post('/user/otp/login', {mobile: this.mobile}).then((result) => {
        this.$vs.loading.close()
        this.$vs.notify({text: result.data.message, color: '#24c1a0'})
        this.step = 2
        this.isCounting = true
        this.count = 60
        this.startCountdown()
      }).catch((err) => {
        this.$vs.loading.close()
        this.$vs.notify({
          color: 'danger',
          text: err.response.data.message})
      })
    },
    startCountdown () {
      this.interval = setInterval(() => {
        this.count--
        if (this.count === 0) {
          clearInterval(this.interval)
        }
      }, 1000)
    },
    resetCount () {
      clearInterval(this.interval)
      // this.count = 60
      // this.startCountdown()
      this.loginOtp()
    },
    loginJWT () {
      // Loading
      this.$vs.loading()

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          mobile: this.mobile,
          otp_code: this.otp_code
        }
      }
      // this.$vs.loading()
      this.$store
        .dispatch('auth/loginJWT', payload)
        .then((response) => {
          this.$store
            .dispatch('auth/fetchUserData')
            .then(() => {
              this.$router.push({
                path: this.$store.state.nextDir || '/mainpage'
              })
              this.$vs.loading.close()
              this.$vs.notify({
                title: 'ورود',
                text: response.data.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              })
            })
            .catch(() => {})
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    }
  },

  mounted () {
    this.$refs.mobileInput.focusInput()
  },
  beforeRouteEnter (from, to, next) {
    const urlParams = new URLSearchParams(window.location.search)
    const myParam = urlParams.get('acc')
    if (myParam) {
      localStorage.setItem('accessToken', myParam)
      next({ path: '/mainpage' })
      // location.reload()
    } else {
      // localStorage.clear()
    }
    return next()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/pages/page-auth.scss";

.vs-inputx  {
    text-align: left !important;
}
.dotted-bg {
  background-image: radial-gradient(rgba(255, 255, 255, 0.226) 10%, transparent 11%),
    radial-gradient(#1a194b2a 10%, transparent 11%);
  background-size: 10px 10px;
  background-position: 0 0, 30px 30px;
  background-repeat: repeat;
}

/*END Fish Animation*/
</style>

